import React, { Component } from "react";
import classnames from "classnames";

class BankInfo extends Component {
  state = {
    bank_name: '',
    bank_address: '',
    bank_code: '',
    account_number: '',
    branch_number: '',
    holder_name: '',
    errors: {}
  }

  onChange = e => {
    this.props.onBankInfoChange(e);
  }

  render () {
    const errors = this.state.errors;
    return (
      <div className="row">
        <div className="col s12 mb">
          <h3>Bank information</h3>
        </div>

        <div className="input-field col s12">
          <input
            id="bank_name"
            type="text"
            value={this.props.bank_name}
            onChange={this.onChange}
            className={classnames("", {
              invalid: errors.bank_name
            })}
          />
          <label htmlFor="bank_name">Bank name</label>
          <span className="red-text">{errors.bank_name}</span>
        </div>
        <div className="input-field col s12">
          <input
            id="bank_address"
            type="text"
            value={this.props.bank_address}
            onChange={this.onChange}
            className={classnames("", {
              invalid: errors.bank_address
            })}
          />
          <label htmlFor="bank_address">Bank address</label>
          <span className="red-text">{errors.bank_address}</span>
        </div>
        <div className="input-field col s12">
          <input
            id="bank_code"
            type="text"
            value={this.props.bank_code}
            onChange={this.onChange}
            className={classnames("", {
              invalid: errors.bank_code
            })}
          />
          <label htmlFor="bank_code">Bank code</label>
          <span className="red-text">{errors.bank_code}</span>
        </div>
        <div className="input-field col s12">
          <input
            id="account_number"
            type="text"
            value={this.props.account_number}
            onChange={this.onChange}
            className={classnames("", {
              invalid: errors.account_number
            })}
          />
          <label htmlFor="account_number">Account number</label>
          <span className="red-text">{errors.account_number}</span>
        </div>
        <div className="input-field col s12">
          <input
            id="branch_number"
            type="text"
            value={this.props.branch_number}
            onChange={this.onChange}
            className={classnames("", {
              invalid: errors.branch_number
            })}
          />
          <label htmlFor="branch_number">Branch number</label>
          <span className="red-text">{errors.branch_number}</span>
        </div>
        <div className="input-field col s12">
          <input
            id="holder_name"
            type="text"
            value={this.props.holder_name}
            onChange={this.onChange}
            className={classnames("", {
              invalid: errors.holder_name
            })}
          />
          <label htmlFor="holder_name">Holder name</label>
          <span className="red-text">{errors.holder_name}</span>
        </div>
      </div>
    );
  }
};

export default BankInfo;
