import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { getBillings } from "../../actions/authActions";
import M from 'materialize-css';
import * as qs from 'query-string';

const calcs = require('../../utils/calculations');

class Dashboard extends Component {
  state = {
    billings: [],
    royalties: null
  }

  componentDidMount () {
    const params = qs.parse(this.props.location.search);
    if (params['show-account-updated']) {
      M.toast({
        html: 'Your account information has been successfully saved.',
        displayLength: 5000
      });
    }

    if (!this.props.auth.isAuthenticated) {
      this.props.history.push("/login");
    } else {
      this.props.getBillings(this.props.auth.user.code)
        .then(() => this.setState(
          {
            billings: this.props.auth.billings.billings,
            royalties: this.props.auth.billings.royalties
          }));
    }
  }

  render () {
    const { user } = this.props.auth;

    return (
      <div style={{ height: "75vh", width: "100%" }}
           className="container valign-wrapper">
        <div className="row">
          <div className="col s12 center-align">
            <h1><b>Welcome {user.bankInfo.first_name}! </b></h1>
            <p>Your account details are provided below.
              Please note that this data includes only the current year.</p>

            <div className="container grey-box">
              <div className="row">
                <div className="col s6">
                  <i className="material-icons large block">camera_alt</i>
                  <span className="box-label">Activated cameras</span>
                </div>

                <div className="col s6">
                  <i className="material-icons large block">attach_money</i>
                  <span className="box-label">Royalties amount</span>
                </div>
              </div>

              <div className="row mt-2">
                <div className="col s6 flex">
                  <div className="circle light-gray-background flex">
                    <span className="big-number">
                      {this.state.billings.length}
                    </span>
                  </div>
                </div>
                <div className="col s6 flex">
                  <div className="circle orange-background flex">
                    <span className="big-number white-text">
                      {calcs.calculateRoyalties(this.state.billings)}
                    </span>
                  </div>
                </div>
              </div>

              <div className="row mt-2">
                <div className="col s6">
                  <Link to="/addSim">
                    <button
                      style={{
                        borderRadius: "3px",
                        letterSpacing: "1.5px"
                      }}
                      type="submit"
                      className="btn-large waves-effect waves-light yellow accent-3 black-text">
                      ADD A CAMERA
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});

export default connect(mapStateToProps, { getBillings })(Dashboard);
