import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { activateAccount } from "../../actions/authActions";
import * as qs from 'query-string';
import * as base64 from 'base-64';

class ActivateAccount extends Component {
  constructor () {
    super();
    this.state = {
      activationToken: '',
      password: '',
      confirmPassword: '',
      validationStarted: false,
      success: false,
      errorToken: false
    };
  }

  componentDidMount () {
    const params = qs.parse(this.props.location.search);
    if (!params['activation-token']) {
      this.setState({
        errorToken: true
      });
    } else {
      let decodedToken;
      try {
        decodedToken = base64.decode(params['activation-token']);
      } catch(error) {
        console.log('error decoding token.') ;
      }

      if (!decodedToken) {
        this.setState({
          errorToken: true
        });
      } else {
        this.setState({
          activationToken: decodedToken
        });
      }
    }
  }

  onChange = e => {
    this.setState({ [e.target.id]: e.target.value });
  };

  showPasswordError () {
    if (!this.state.validationStarted) return false;

    if (this.state.password &&
        (this.state.password !== this.state.confirmPassword)) {
      return true;
    } else {
      return false;
    }
  }

  onSubmit = e => {
    e.preventDefault();

    this.setState({
      validationStarted: true
    });

    if (this.showPasswordError()) return;

    activateAccount({
      activationToken: this.state.activationToken,
      password: this.state.password
    })
      .then(response => {
        if (response.status === 404) {
          this.setState({
            errorToken: true
          });
        } else if (response.status === 400) {
          this.setState({
            errors: response.data.errors
          })
        } else if (response.status === 200) {
          this.setState({
            success: true
          });
        }
      });
  };

  render () {
    if (this.state.success) {
      return (
      <div className="container login-form">
        <div style={{ marginTop: "4rem" }} className="row">
          <div className="col s12">
            <h4>Your account is ready!</h4>
            <h4>
              You can now <Link to="/login">log in</Link>
              &nbsp;and start using our loyalty program.
            </h4>
          </div>
        </div>
      </div>
      )
    }

    if (this.state.errorToken) {
      return (
      <div className="container login-form">
        <div style={{ marginTop: "4rem" }} className="row">
          <div className="col s12">
            <h4>Sorry, an error occured while processing your request.</h4>
            <h4>
              Please <Link to="/login">log in</Link>
              &nbsp;in order to use your account.
            </h4>
          </div>
        </div>
      </div>
      )
    }

    return (
      <div className="container login-form">
        <div style={{ marginTop: "4rem" }} className="row">
          <div className="col s12">
            <div className="col s12" style={{ paddingLeft: "11.250px" }}>
              <h3>Activate your account</h3>
            </div>
            <form noValidate onSubmit={this.onSubmit}>
              <div className="input-field col s12">
                <input
                  onChange={this.onChange}
                  value={this.state.password}
                  id="password"
                  type="password" />
                <label htmlFor="password">Choose a password</label>
                { this.state.errors &&
                  <span className="red-text">
                    { this.state.errors.password }
                  </span>
                }
              </div>

              <div className="input-field col s12">
                <input
                  onChange={this.onChange}
                  value={this.state.confirmPassword}
                  id="confirmPassword"
                  type="password" />
                <label htmlFor="confirmPassword">Confirm your password</label>
                { this.showPasswordError() &&
                  <span className="red-text">
                    Your passwords do not match
                  </span>
                }
              </div>
              <div className="col s12" style={{ paddingLeft: "11.250px" }}>
                <button
                  style={{
                    width: "150px",
                    borderRadius: "3px",
                    letterSpacing: "1.5px",
                    marginTop: "1rem"
                  }}
                  disabled={
                    !this.state.password || !this.state.confirmPassword }
                  type="submit"
                  className="btn-large waves-effect waves-light white-text">
                  Activate
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(ActivateAccount);
