import React, { Component } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import M from 'materialize-css';
const countries = require("i18n-iso-countries");
const statesPicker = require('country-state-picker');
countries.registerLocale(require("i18n-iso-countries/langs/en.json"));

const CHOOSE_PROVINCE_OPTION = 'Choose your province';
const CHOOSE_COUNTRY_OPTION = 'Choose your country';

class AccountInfo extends Component {
  componentDidMount () {
    const elems = document.querySelectorAll('select');
    M.FormSelect.init(elems, {});
    M.updateTextFields();
  }

  componentDidUpdate () {
    const elems = document.querySelectorAll('select');
    M.FormSelect.init(elems, {});
    M.updateTextFields();
  }

  renderStates = () => {
    let states = []
    const countryCode = this.props.data.country ?
          countries.getAlpha2Code(this.props.data.country, 'en') : undefined;

    if (this.props.data.country !== CHOOSE_COUNTRY_OPTION && countryCode) {
      states = statesPicker.getStates(countryCode.toLowerCase());
    }

    if (states && states.length > 0) {
      states.sort();
      states = [CHOOSE_PROVINCE_OPTION, ...states];
      return states.map(
        (state) =>
          <option key={state}
                  value={state}
                  disabled={ state === CHOOSE_PROVINCE_OPTION }>
            { state }
          </option>
      );
    } else {
      return (
        <option value={ CHOOSE_PROVINCE_OPTION }
                key={ CHOOSE_PROVINCE_OPTION }>
          { CHOOSE_PROVINCE_OPTION}
        </option>
      );
    }
  }

  renderCountries = () => {
    var countryNs = countries.getNames("en");
    var countryNames = [];
    for (var key in countryNs){
      countryNames.push(countryNs[key]);
    }
    countryNames.sort();
    countryNames = [CHOOSE_COUNTRY_OPTION, ...countryNames];
    return countryNames.map(
      (countryName) =>
        <option key={countryName}
                disabled={ countryName === CHOOSE_COUNTRY_OPTION }
                value={countryName}>
          { countryName }
        </option>
    );
  }

  onChange = e => {
    this.props.onDataChange(e);
  };

  render () {
    const { data, errors } = this.props;

    return (
      <div>
        <div className="row">
          <div className="col s12 mb">
            <h3>Identification</h3>
          </div>

          <div className="col s6 input-field">
            <input
              onChange={this.onChange}
              value={data.first_name}
              error={errors.first_name}
              disabled={ this.props.readOnly }
              id="first_name"
              type="text"
              className={classnames("", {
                invalid: errors.first_name
              })}
            />
            <label htmlFor="first_name">First name</label>
            <span className="red-text">{errors.first_name}</span>
          </div>

          <div className="col s6 input-field">
            <input
              onChange={this.onChange}
              value={data.last_name}
              error={errors.last_name}
              disabled={ this.props.readOnly }
              id="last_name"
              type="text"
              className={classnames("", {
                invalid: errors.last_name
              })}
            />
            <label htmlFor="last_name">Last name</label>
            <span className="red-text">{errors.last_name}</span>
          </div>
        </div>

        <div className="row">
          <div className="input-field col s12">
            <input
              onChange={this.onChange}
              value={data.email}
              error={errors.email}
              disabled={ this.props.emailReadOnly || this.props.readOnly }
              id="email"
              type="email"
              className={classnames("", {
                invalid: errors.email
              })}
            />
            <label htmlFor="email">Email</label>
            <span className="red-text">{errors.email}</span>
          </div>
        </div>

        <div className="row">
          <div className="col s12 mb">
            <h3>Contact information</h3>
          </div>

          <div className="col s12 input-field">
            <input
              onChange={this.onChange}
              value={data.company_name}
              error={errors.company_name}
              disabled={ this.props.readOnly }
              id="company_name"
              type="text"
              className={classnames("", {
                invalid: errors.company_name
              })}
            />
            <label htmlFor="company_name">Company name</label>
            <span className="red-text">{errors.company_name}</span>
          </div>

          <div className="col s6 input-field">
            <input
              onChange={this.onChange}
              value={data.company_phone_number}
              error={errors.company_phone_number}
              disabled={ this.props.readOnly }
              id="company_phone_number"
              type="text"
              className={classnames("", {
                invalid: errors.company_phone_number
              })}
            />
            <label htmlFor="company_phone_number">Company phone number</label>
            <span className="red-text">{errors.company_phone_number}</span>
          </div>

          <div className="col s6 input-field">
            <input
              onChange={this.onChange}
              value={data.phone_number}
              error={errors.phone_number}
              disabled={ this.props.readOnly }
              id="phone_number"
              type="text"
              className={classnames("", {
                invalid: errors.phone_number
              })}
            />
            <label htmlFor="phone_number">Contact phone number</label>
            <span className="red-text">{errors.phone_number}</span>
          </div>
        </div>

        <div className="row">
          <div className="col s6 input-field">
            <input
              onChange={this.onChange}
              value={data.address}
              error={errors.address}
              disabled={ this.props.readOnly }
              id="address"
              type="text"
              className={classnames("", {
                invalid: errors.address
              })}
            />
            <label htmlFor="address">Address</label>
            <span className="red-text">{errors.address}</span>
          </div>

          <div className="col s6 input-field">
            <input
              onChange={this.onChange}
              value={data.city}
              error={errors.city}
              disabled={ this.props.readOnly }
              id="city"
              type="text"
              className={classnames("", {
                invalid: errors.city
              })}
            />
            <label htmlFor="city">City</label>
            <span className="red-text">{errors.city}</span>
          </div>
        </div>

        <div className="row">
          <div className="col s4 input-field">
            <select
              onChange={this.onChange}
              value={ data.country }
              error={errors.country}
              disabled={ this.props.readOnly }
              id="country"
              className={classnames("", {
                invalid: errors.country
              })}>
              { this.renderCountries() }
            </select>

            <span className="red-text">{errors.country}</span>
          </div>

          <div className="col s4 input-field">
            <select
              value={ data.province }
              onChange={this.onChange}
              error={errors.province}
              disabled={ this.props.readOnly }
              id="province"
              className={classnames("", {
                invalid: errors.province
              })}>
              { this.renderStates() }
            </select>

            <span className="red-text">{errors.province}</span>
          </div>

          <div className="col s4 input-field">
            <input
              onChange={this.onChange}
              value={data.postal_code}
              error={errors.postal_code}
              disabled={ this.props.readOnly }
              id="postal_code"
              type="text"
              className={classnames("", {
                invalid: errors.postal_code
              })}
            />
            <label htmlFor="postal_code">Postal/Zip code</label>
            <span className="red-text">{errors.postal_code}</span>
          </div>
        </div>
      </div>
    );
  }
}

AccountInfo.propTypes = {
  onDataChange: PropTypes.func,
  errors: PropTypes.object,
  data: PropTypes.object.isRequired,
  emailReadOnly: PropTypes.bool,
  readOnly: PropTypes.bool
};

export {
  AccountInfo,
  CHOOSE_COUNTRY_OPTION,
  CHOOSE_PROVINCE_OPTION
};
