import React from 'react'
import PropTypes from "prop-types"
import M from 'materialize-css'

class CustomPagination extends React.Component {
  componentDidMount () {
    const elems = document.querySelectorAll('select')
    M.FormSelect.init(elems, {})
  }

  handleFirstPage = () => {
    this.props.onChangePage(1)
  }

  handlePreviousPage = () => {
    this.props.onChangePage(this.props.currentPage -1)
  }

  handleNextPage = () => {
    this.props.onChangePage(this.props.currentPage + 1)
  }

  handleLastPage = () => {
    this.props.onChangePage(this.props.rowCount)
  }

  handleRowsPerPageChange = e => {
    this.props.onChangeRowsPerPage(parseInt(e.target.value))
  }

  render () {
    return (
      <div className="container mt">
        <div className="row flex">
          <div className="col s2 offset-s5 input-field">
            <select
              onChange={ this.handleRowsPerPageChange }
              defaultValue={ this.props.rowsPerPage }>
              <option>10</option>
              <option>15</option>
              <option>20</option>
              <option>25</option>
            </select>
            <label>Rows per page</label>
          </div>

          <div className="col s3"
               style={{ marginLeft: '15px' }}>
            <span>
              Page { this.props.currentPage } of { this.props.rowCount }
            </span>
          </div>

          <div className="flex col s3">
            <div className="col s1">
              <button
                onClick={ this.handleFirstPage }
                disabled={ this.props.currentPage === 1 }
                className="btn-flat">
                <i className="material-icons">
                  first_page
                </i>
              </button>
            </div>

            <div className="col s1">
              <button
                onClick={ this.handlePreviousPage }
                disabled={ this.props.currentPage === 1 }
                className="btn-flat">
                <i className="material-icons">
                  navigate_before
                </i>
              </button>
            </div>

            <div className="col s1">
              <button
                onClick={ this.handleNextPage }
                disabled={ this.props.rowCount < 2  ||
                           this.props.currentPage === this.props.rowCount }
                className="btn-flat">
                <i className="material-icons">
                  navigate_next
                </i>
              </button>
            </div>
            <div className="col s1">
              <button
                onClick={ this.handleLastPage }
                disabled={ this.props.rowCount < 2 ||
                           this.props.currentPage === this.props.rowCount }
                className="btn-flat">
                <i className="material-icons">
                  last_page
                </i>
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

CustomPagination.propTypes = {
  rowsPerPage: PropTypes.number.isRequired,
  rowCount: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangeRowsPerPage: PropTypes.func.isRequired,
  currentPage: PropTypes.number.isRequired,
}

export default CustomPagination
