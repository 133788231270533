import React, { Component } from 'react';

import LRG from '../../images/Integrator-Login_LRG.png';
import SSI from '../../images/Integrator-Login_SSI.png';
import TMG from '../../images/Integrator-Login_TMG.png';

class Footer extends Component {
    render() {
        return (
            <div className="footer">
                <hr />
                <ul className="sponsor-logos">
                    <li>
                        <a href="https://www.lanrepgroup.com">
                            <img src={LRG} alt="LRG" />
                        </a>
                    </li>
                    <li>
                        <a href="https://www.4ademo.com/index.php">
                            <img src={SSI} alt="SSI" />
                        </a>
                    </li>
                    <li>
                        <a href="http://www.tmgrepgroup.com/">
                            <img src={TMG} alt="TMG" />
                        </a>
                    </li>
                </ul>
            </div>
        );
    }
}

export default Footer;
