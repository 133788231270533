import React from "react"
import { Route, Redirect } from "react-router-dom"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import jwt_decode from "jwt-decode"
import { store } from "../../store"
import { logoutUser } from "../../actions/authActions"

function validateToken () {
  if (!localStorage.jwtToken) {
    return false
  }

  const token = localStorage.jwtToken
  const decoded = jwt_decode(token)

  if (!decoded.data) return false

  const currentTime = Date.now() / 1000;
  if (decoded.exp < currentTime) {
    store.dispatch(logoutUser())
    window.location.href = "#/login"
    return false
  }

  return true
}

function isAdmin () {
  const token = localStorage.jwtToken
  const decoded = jwt_decode(token)

  if (decoded.data.isAdmin) return true
  return false
}

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props => {
      const validated = validateToken()
      if (!validated)
        return (<Redirect to="/" />)
      else
        return (
          <Component {...props} />
        )
    }}
  />
)

PrivateRoute.propTypes = {
  component: PropTypes.object.isRequired
}

const AdminRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props => {
      const validated = validateToken()
      if (!validated)
        return (<Redirect to="/" />)
      else if (isAdmin())
        return (
          <Component {...props} />
        )
      else
        return (<Redirect to="/dashboard" />)
    }}
  />
)

AdminRoute.propTypes = {
  component: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  auth: state.auth
})

const connectedPrivateRoute = connect(mapStateToProps)(PrivateRoute)
const connectedAdminRoute = connect(mapStateToProps)(AdminRoute)

export {
  connectedPrivateRoute as PrivateRoute,
  connectedAdminRoute as AdminPrivateRoute
}
