import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import classnames from "classnames";
import { addBilling } from "../../actions/authActions";
import { getBillings } from "../../actions/authActions";
import ErrorMessage from "../errors/ErrorMessage";
import whereToFindCodeImg from '../../images/where_to_find_code.png';
import * as moment from 'moment';

const calcs = require('../../utils/calculations');

function formatDate (date) {
  return moment(date).format('YYYY-MM-DD');
}

class AddSim extends Component {
  constructor (props) {
    super(props);
    this.state = {
      number: '',
      billings: [],
      showError: false,
      isLoading: false,
      errors: {},
    }
  }

  componentDidMount () {
    if (!this.props.auth.isAuthenticated) {
      this.props.history.push("/login");
    } else {
      this.props.getBillings(this.props.auth.user.code)
        .then(() => this.setState({
          billings: this.props.auth.billings.billings
        }));
    }
  }

  componentDidUpdate () {
    if (!this.props.auth.isAuthenticated) {
      this.props.history.push("/login");
    }
  }

  onChange = e => {
    this.setState({ [e.target.id]: e.target.value });
  }

  onLoad = () => {
    this.setState( prevState => ({
      isLoading: !prevState.isLoading
    }))
  }

  onSubmit = e => {
    e.preventDefault();
    this.onLoad();
    const { user } = this.props.auth;
    const userData = {
      number: this.state.number,
      code: user.code,
    };

    this.props.addBilling(userData).then(res => {
      if (res && res.status === 200) {
        let billings = this.state.billings;
        billings.push(res.data);
        this.setState({
          errors: {},
          showError: false,
          billings: billings,
          number: ''
        });
      } else if (this.props.errors.number) {
        this.numberInput.select();
        this.setState({
          errors: this.props.errors,
          showError: true
        });
      }
      this.onLoad()
    });
  }

  closeErrorMessage = () => {
    this.setState({
      showError: false
    });
  }

  renderErrorMessage = () => {
    const errorMess = "Camera with given sim number is already " +
          "activated. If this is not the case, please contact us.";
    if (this.state.errors.number &&
        this.state.errors.number.includes('already exists') &&
        this.state.showError){
      return (
        <ErrorMessage
          text={errorMess}
          closePopUp={this.closeErrorMessage}/>
      );
    }
  }

  render () {
    const { errors } = this.state;
    return (
      <div className="container">
        <h3>Please enter a valid sim or serial number</h3>
        <div className="ui segment"
             style={{ padding: '10px', marginBottom: '20px' }}>
          <form noValidate onSubmit={this.onSubmit}>

            <div className="input-field col s8" padding="0">
              <input
                onChange={this.onChange}
                value={this.state.number}
                error={errors.number}
                id="number"
                type="text"
                ref={ (input) => this.numberInput = input }
                maxLength={20}
                className={classnames("", {
                  invalid: errors.number
                })}
              />
              <label htmlFor="number">Sim or serial number</label>
              <span className="red-text">{errors.number}</span>
            </div>
            <div className="col s12" style={{ paddingLeft: "11.250px" }}>
              <button
                disabled={ this.state.isLoading }
                style={{
                  width: "100px",
                  borderRadius: "3px",
                  marginTop: "1rem"
                }}
                type="submit"
                className="btn btn-small hoverable blue accent-3">
                Submit
              </button>
            </div>

          </form>
        </div>
        <div className="ui segment" >
          <div><SimsList billings={this.state.billings} /></div>
        </div>

        <div className="ui segment">
          <img id="where-to-find-code"
            src={whereToFindCodeImg} alt="Where to find activation code"
            style={{ marginTop: '20px', float: 'right', height: '350px' }} />
        </div>
      </div>
    );

  }
}

const SimsList = (props) => {
  const billings = props.billings;
  let count = 0;
  if (billings.length > 0) {
    if (props.billings.length > 99) {
      count = props.billings.length - 99;
    }
    const billings_ToDisplay = props.billings.map((billing) => {
      let royalties = 0;

      if (count > 0) {
        royalties = Math.floor(
          calcs.calculateTransactionsSum(
            billing.transactions) * 0.15 * 100) / 100;
        count--;
      } else {
        royalties = Math.floor(
          calcs.calculateTransactionsSum(
            billing.transactions) * 0.1 * 100) / 100;
      }

      return (
        <tr key={billing._id}>
          <td>{billing.sim_number}</td>
          <td>{billing.serial_number}</td>
          <td>{royalties}</td>
          <td>{formatDate(billing.date)}</td>
        </tr>
      );

    });
    return (
      <div>
        <h4 style={{ color: '#2979FF' }}>
          <b>You have {props.billings.length} cameras activated</b>
        </h4>
        <table className="striped">
          <thead>
            <tr>
              <th>Sim number</th>
              <th>Serial</th>
              <th>Royalties</th>
              <th>Activation date</th>
            </tr>
          </thead>
          <tbody>{billings_ToDisplay}</tbody>
        </table>
      </div>)
  } else { return <p><b>You have not yet registered cameras</b></p> }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    errors: state.errors
  };
};

export default connect(
  mapStateToProps,
  { addBilling, getBillings }
)(withRouter(AddSim));
