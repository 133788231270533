const initialState = {};

export default function (state = initialState, action) {
  switch (action.type) {
    case 'LOAD_USER_DETAIL':
      return {
        ...state,
        userData: action.payload
      }
    case 'FETCH_ROYALTIES':
      return {
        ...state,
        integrators: action.payload
      };
    case 'UPDATE_TRANSACTIONS':
      return {
        ...state,
        transUpdateMessage: action.payload
      };
    case 'FETCH_AWARDS':
      return {
        ...state,
        rewards: action.payload
      }
    default:
      return state;
  }
}
