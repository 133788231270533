import React from 'react'
import PropTypes from "prop-types"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import { AccountInfo } from '../accountManipulation/AccountInfo'
import M from 'materialize-css';
import * as moment from 'moment'
import { DATE_FORMAT } from './constants'
import { confirmAccount, declineAccount } from '../../actions/adminActions'

class UserDetail extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      ready: false,
      loading: false,
      waitingActivation: false
    }
  }

  componentDidMount() {
    let newState = { ready: true }

    if (this.props.data) {
      if (this.props.data.waitingActivation) {
        newState.waitingActivation = true
      }
      this.setState(newState)
    } else {
      this.props.history.push('/admin/users')
    }
  }

  handleGoBack = () => {
    this.props.history.push('/admin/users')
  }

  handleConfirm = () => {
    this.setState({
      loading: true
    });

    confirmAccount(this.props.data.id)
      .then(() => {
        this.setState({
          waitingActivation: false
        })
        M.toast({
          html: 'This account has been successfully confirmed.',
          displayLength: 5000
        })
      })
      .catch(() => {
        M.toast({
          html: 'An error occured while confirming this ' +
            'account. Please try again.',
          displayLength: 5000
        })
      })
      .finally(() => {
        this.setState({
          loading: false
        });
      })
  }

  handleDecline = () => {
    this.setState({
      loading: true
    });

    declineAccount(this.props.data.id)
      .then(() => {
        this.setState({
          waitingActivation: false
        })
        M.toast({
          html: 'This account has been successfully deleted.',
          displayLength: 5000
        })
      })
      .catch(() => {
        M.toast({
          html: 'An error occured while deleting this ' +
            'account. Please try again.',
          displayLength: 5000
        })
      })
      .finally(() => {
        this.setState({
          loading: false
        });
      })
  }

  renderApprovalPanel() {
    if (!this.state.waitingActivation) {
      return '';
    }

    return (
      <div className="row">
        <div className="col s12">
          <div className="card-panel">
            <h5>This account is waiting approval</h5>
            <span className="panel-label-title">Registration date: </span>
            <span className="panel-label-value">
              {moment(this.props.data.date).format(DATE_FORMAT)}
            </span>
            <span className="panel-label-title">Camera bought from: </span>
            <span className="panel-label-value">
              {this.props.data.provenance.toUpperCase()}
            </span>

            <div className="row">
              <div className="input-field col s3">
                <button
                  style={{
                    borderRadius: "3px",
                    letterSpacing: "1.5px",
                    marginRight: "10px"
                  }}
                  onClick={this.handleConfirm}
                  disabled={this.state.loading}
                  className="btn waves-effect waves-light white-text">
                  confirm
                </button>

                <button
                  style={{
                    borderRadius: "3px",
                    letterSpacing: "1.5px"
                  }}
                  disabled={this.state.loading}
                  onClick={this.handleDecline}
                  className="btn waves-effect waves-light white-text">
                  decline
                </button>
              </div>
            </div>

            {this.state.loading &&
              (
                <div className="row">
                  <div className="col s12">
                    <div className="progress">
                      <div className="indeterminate"></div>
                    </div>
                  </div>
                </div>
              )
            }
          </div>
        </div>
      </div>
    )
  }

  renderResendLinkButton() {
    if (this.state.waitingActivation || this.props.data.isActive || this.props.data.isDeleted) return ''
    return (
      <button
        style={{
          borderRadius: "3px",
          letterSpacing: "1.5px"
        }}
        onClick={this.handleConfirm}
        className="btn waves-effect waves-light white-text grey darken-2">
        Resend link
      </button>
    );
  }

  render() {
    if (!this.state.ready) return '';

    return (
      <div className="container">
        <div className="row">
          <div className="input-field col s6">
            <button
              style={{
                borderRadius: "3px",
                letterSpacing: "1.5px",
                marginRight: "10px"   
              }}
              onClick={this.handleGoBack}
              className="btn waves-effect waves-light white-text grey darken-2">
              Go back
            </button>
            {this.renderResendLinkButton()}
          </div>
        </div>
        { this.renderApprovalPanel()}

        <div className="row">
          <div className="col s12 mb-2">
            <AccountInfo data={this.props.data}
              errors={{}}
              readOnly />
          </div>
        </div>
      </div>
    );
  }
}

UserDetail.propTypes = {
  data: PropTypes.object,
  history: PropTypes.object
};

function mapStateToProps(state) {
  return {
    data: state.admin.userData
  }
}

export default withRouter(connect(mapStateToProps)(UserDetail))
