import React, { Component } from "react";

class ConfirmRegister extends Component {

  render () {
    return (
      <div style={{ height: "75vh" }} className="container valign-wrapper">
        <div className="row">
          <div className="landing-copy col s12 center-align">
            <h3>Thank you for subscribing!</h3>
            <p className="register-confirmation-message">
              You will receive a confirmation email as soon as our sales
              department approves your subscription.
              <br />
              Have a great day!
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default ConfirmRegister;
