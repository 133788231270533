import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import M from 'materialize-css';
import logo from '../../images/logo.png';
import { logoutUser } from "../../actions/authActions";

class Navbar extends Component {
  componentDidMount () {
    const elems = document.querySelectorAll('.dropdown-trigger');
    M.Dropdown.init(elems, {
      constrainWidth: false,
      coverTrigger: false
    });
  }

  componentDidUpdate () {
    const elems = document.querySelectorAll('.dropdown-trigger');
    M.Dropdown.init(elems, {
      constrainWidth: false,
      coverTrigger: false
    });
  }

  renderAccountMenu () {
    if (this.props.auth.isAuthenticated) {
      const isAdmin = this.props.auth.user.isAdmin;
      const homeLink = isAdmin ? '/admin' : '/dashboard';
      return (
        <div>
          <ul id="menu-dropdown" className="dropdown-content">
            { !isAdmin &&
              (<li>
                 <Link to="updateaccount" className="item">
                   Update account
                 </Link>
               </li>
              )
            }

            <li>
              <Link to="changePassword" className="item">
                Change password
              </Link>
            </li>

            { !isAdmin &&
              (<li>
                 <Link to="addSim" className="item">
                   Add a camera
                 </Link>
               </li>
              )
            }

            <li>
              <Link to="/"
                    className="link item"
                    onClick={this.onLogoutClick}>
                Logout
              </Link>
            </li>
          </ul>

          <li><Link to={ homeLink } className="link item">Home</Link></li>
          <li>
            <a className="dropdown-trigger"
               href="#!"
               data-target="menu-dropdown">
              {this.props.auth.user.bankInfo.first_name}
              <i className="material-icons right">account_circle</i>
            </a>
          </li>
        </div>
      );
    } else {
      return '';
    }
  }

  renderRightMenu () {
    if (this.isAuthenticated()) {
      return (
        <ul className="right hide-on-med-and-down">
          { this.renderAccountMenu() }
        </ul>
      );
    } else {
      return (
        <ul className="right hide-on-med-and-down">
          <li><Link to="/" className="ui item">Home</Link></li>
          <li><Link to="login" className="ui item">Login</Link></li>
          <li><Link to="register" className="ui item">Sign up</Link></li>
        </ul>
      );
    }
  }

  isAuthenticated () {
    if (this.props.auth.user && this.props.auth.isAuthenticated)
      return true;
    return false;
  }

  onLogoutClick = e => {
    e.preventDefault();
    this.props.history.push('/')
    this.props.logoutUser();
  };

  render () {
    return (
      <div className="navbar-fixed">
        <nav>
          <div className="nav-wrapper">
            <div className="brand-logo">
              <img src={logo}
                   alt="Vosker integrator portal"
                   height="75%" />
            </div>

            { this.renderRightMenu() }
          </div>
        </nav>
      </div>
    );
  }
}

Navbar.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default withRouter(connect(
  mapStateToProps,
  { logoutUser }
)(Navbar));
