import React from 'react';
import { changePassword } from '../../actions/authActions';
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import classnames from "classnames";
const Validator = require("validator");
const isEmpty = require("is-empty");

function validatePasswordInput (data) {
  let errors = {};

  // Convert empty fields to an empty string so we can use validator functions
  data.old_password = !isEmpty(data.old_password) ? data.old_password : "";
  data.new_password = !isEmpty(data.new_password) ? data.new_password : "";
  data.new_password2 = !isEmpty(data.new_password2) ? data.new_password2 : "";

  // Password checks
  if (Validator.isEmpty(data.old_password)) {
    errors.old_password = "Password field is required";
  } else if (!Validator.isLength(data.new_password, { min: 6, max: 30 })) {
    errors.new_password = "Password must be at least 6 characters";
  }

  if (Validator.isEmpty(data.new_password)) {
    errors.new_password = "Password field is required";
  }

  if (Validator.isEmpty(data.new_password2)) {
    errors.new_password2 = "Password field is required";
  }

  if (!Validator.equals(data.new_password, data.new_password2)) {
    errors.new_password2 = "Passwords must match";
  }

  return {
    errors,
    isValid: isEmpty(errors)
  }
}

class ChangePassword extends React.Component {
  state = {
    old_password: '',
    new_password: '',
    new_password2: '',
    errors: {}
  }

  componentDidMount = () => {
    if (!this.props.auth.isAuthenticated) {
      this.props.history.push("/login");
    }
  }

  componentWillReceiveProps (nextProps) {
    if (!nextProps.auth.isAuthenticated) {
      this.props.history.push("/login");
    }
    if (nextProps.errors) {
      this.setState({
        errors: nextProps.errors
      });
    }
  }

  onChange = (e) => {
    this.setState({ [e.target.id]: e.target.value })
  }

  onSubmit = (e) => {
    e.preventDefault();
    const { user } = this.props.auth;
    const userData = {
      old_password: this.state.old_password,
      new_password: this.state.new_password,
      new_password2: this.state.new_password2,
      code: user.code
    }
    const errs = validatePasswordInput(userData);
    if (!errs.isValid) {
      this.setState({ errors: errs.errors });
    } else {
      this.props.changePassword(userData, this.props.history, user.isAdmin);
    }
  }

  render () {
    const errors = this.state.errors;
    return (
      <div className="container">
        <div style={{ marginTop: "4rem" }} className="row">
          <div className="col s8 offset-s2">
            <form noValidate onSubmit={this.onSubmit}>
              <div className="col s12">
                <h3>
                  <strong>Change your password</strong>
                </h3>
              </div>
              <div className="input-field col s12">
                <input
                  onChange={this.onChange}
                  value={this.state.old_password}
                  id="old_password"
                  type="password"
                  className={classnames("", {
                    invalid: errors.old_password || errors.passwordincorrect
                  })}
                />
                <label htmlFor="old_password">Old Password</label>
                {<span className="red-text">
                                              {errors.old_password}
                                    {errors.passwordincorrect}
                                  </span>}
              </div>
              <div className="input-field col s12">

                <input
                  onChange={this.onChange}
                  value={this.state.new_password}
                  id="new_password"
                  type="password"
                  className={classnames("", {
                    invalid: errors.new_password
                  })}
                />
                <label htmlFor="new_password">New Password</label>
                {<span className="red-text">{errors.new_password}</span>}
              </div>
              <div className="input-field col s12">
                <input
                  onChange={this.onChange}
                  value={this.state.new_password2}
                  id="new_password2"
                  type="password"
                  className={classnames("", {
                    invalid: errors.new_password2
                  })}
                />
                <label htmlFor="new_password2">Confirm New Password</label>
                <span className="red-text">{errors.new_password2}</span>
              </div>
              <div className="right floated content">
                <button
                  className="btn-large waves-effect waves-light white-text"
                  style={{
                    width: "150px",
                    borderRadius: "3px",
                    letterSpacing: "1.5px",
                    marginTop: "1rem"
                  }}
                  type="submit">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

ChangePassword.propTypes = {
  changePassword: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});

export default connect(
  mapStateToProps,
  { changePassword }
)(withRouter(ChangePassword));
