import React from 'react';


const ErrorMessage = (props) => {
    
    return (
        <div className="popup">
            <div className="popup_inner">
            <i className="medium material-icons" style={{margin: "0 auto"}}>error</i>
                <h3 style={{textAlign: "center", marginTop:"5px"}}>
                    <b>{props.text}</b></h3>
                <button className="btn btn-small amber darken-4 hoverable white-text accent-3"
                style={{
                    display: "block",
                    marginLeft:"auto",
                    marginRight: "auto",
                    marginTop: "20px",
                    borderRadius: "3px",
                    letterSpacing: "1.5px"
                  }}
                  onClick={props.closePopUp}>Close</button>
            </div>
        </div>
    );
}

export default ErrorMessage;