import React from 'react'
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import { getAccounts } from '../../actions/adminActions'
import DataTable from 'react-data-table-component'
import CustomPagination from './CustomPagination'
import * as moment from 'moment'
import { flattenUserData } from '../../utils/converters'
import { DATE_FORMAT } from './constants'

const columns = [
  {
    name: 'Email',
    selector: 'email'
  },
  {
    name: 'First name',
    selector: 'bankInfo.first_name'
  },
  {
    name: 'Last name',
    selector: 'bankInfo.last_name'
  },
  {
    name: 'Company name',
    selector: 'bankInfo.company_name'
  },
  {
    name: 'Country',
    selector: 'bankInfo.country'
  },
  {
    name: 'Date',
    selector: row => {
      return moment(row.date).format(DATE_FORMAT)
    }
  },
  {
    name: 'Status',
    selector: row => {
      if (row.isDeleted) {
        return 'Deleted'
      } else if (row.isActive) {
        return 'Active'
      } else if (row.waitingActivation && !row.activationToken) {
        return 'Pending'
      } else if (row.waitingActivation) {
        return 'Approved'
      }
    }
  },
]

class UserList extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      rows: [],
      filterValue: '',
      totalPages: 0,
      totalRowsPerPage: 10
    }
  }

  refreshAccounts (page = 1, toApprove = false) {
    let options = {
      limit: this.state.totalRowsPerPage,
      page: page,
      filter: this.state.filterValue
    }

    if (toApprove)
      options['waiting-approval'] = '1'

    getAccounts(options).then(response => {
      this.setState({
        rows: response.data.rows,
        totalPages: response.data.totalPages
      })
    })
    .catch(() => {
      console.log('Could not fetch accounts.')
    })
  }

  componentDidMount () {
    this.refreshAccounts()
  }

  toggleFilterApproval = (e) => {
    this.refreshAccounts(1, e.target.checked)
  }

  handleChange = (e) => {
    this.setState({
      filterValue: e.target.value
    }, () => {
      this.refreshAccounts()
    })
  }

  handleGoBack = () => {
    this.props.history.push('/admin/')
  }

  handlePageChange = page => {
    this.refreshAccounts(page)
  }

  handleRowsPerPageChange  = (newTotal, page) => {
    this.setState({
      totalRowsPerPage: newTotal
    }, () => {
      this.refreshAccounts(page)
    })
  }

  handleRowClick = (data) => {
    this.props.dispatch({
      type: 'LOAD_USER_DETAIL',
      payload: flattenUserData(data)
    })
    this.props.history.push('/admin/users/detail')
  }

  render () {
    return (
      <div className="container">
        <div className="row">
          <div className="input-field col s6">
            <button
              style={{
                borderRadius: "3px",
                letterSpacing: "1.5px"
              }}
              onClick={ this.handleGoBack }
              className="btn waves-effect waves-light white-text grey darken-2">
              Go back
            </button>
          </div>
        </div>

        <div className="row">
          <div className="col s3">
            <h3>Integrators</h3>
          </div>
        </div>

        <div className="row mt">
          <div className="input-field col s6">
            <i className="material-icons prefix">search</i>
            <input
              id="icon_prefix"
              type="text"
              onChange={ this.handleChange }
              placeholder="Enter email, first name, last name, company name"/>
          </div>
        </div>

        <div className="row">
          <div className="input-field col s6">
            <p>
              <label>
                <input
                  type="checkbox"
                  onChange={ this.toggleFilterApproval }/>
                <span style={{ fontSize: '1.5em' }}>
                  Show accounts waiting for approval
                </span>
              </label>
            </p>
          </div>
        </div>

        <div className="row">
          <div className="col s12">
            <DataTable
              noHeader
              pagination
              paginationServer
              paginationComponent={ CustomPagination }
              paginationTotalRows={ this.state.totalPages }
              paginationPerPage={ this.state.totalRowsPerPage }
              onChangeRowsPerPage={ this.handleRowsPerPageChange }
              onChangePage={ this.handlePageChange }
              onRowClicked={ this.handleRowClick }
              pointerOnHover={ true }
              highlightOnHover={ true }
              columns={ columns }
              data={ this.state.rows }
            />
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(connect()(UserList))
