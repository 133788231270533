function flattenUserData (data) {
  const waitingActivation = data.waitingActivation && !data.activationToken
  return {
    id: data._id,
    waitingActivation: waitingActivation,
    date: data.date,
    email: data.email,
    first_name: data.bankInfo.first_name,
    last_name: data.bankInfo.last_name,
    company_name: data.bankInfo.company_name,
    address: data.bankInfo.address,
    city: data.bankInfo.city,
    province: data.bankInfo.province,
    country: data.bankInfo.country,
    postal_code: data.bankInfo.postal_code,
    phone_number: data.bankInfo.phone_number,
    company_phone_number: data.bankInfo.company_phone_number,
    provenance: data.provenance,
    division: data.division,
    isActive: data.isActive,
    isDeleted: data.isDeleted
  }
}

export {
  flattenUserData
}
