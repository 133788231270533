import axios from "axios";
import setAuthToken from "../utils/setAuthToken";

import { GET_ERRORS, SET_CURRENT_USER, USER_LOADING } from "./types";

// Set logged in user
export const setCurrentUser = user => {
  return {
    type: SET_CURRENT_USER,
    payload: user
  }
};

// User loading
export const setUserLoading = () => {
  return {
    type: USER_LOADING
  };
};

// Register User
export const registerUser = (userData, history) => dispatch => {
  axios
    .post("api/register", userData)
    .then(() => history.push("/confirmreg"))
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    );
};

// Login - get user token
export const loginUser = userData => dispatch => {
  axios
    .post("/api/login", userData)
    .then(res => {
      const { token } = res.data;
      localStorage.setItem("jwtToken", token);
      // Set token to Auth header
      setAuthToken(token);
      dispatch(setCurrentUser(res.data.payload));
    })
    .catch(err => {
      console.log(err)
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    })
};

export const updateAccount = (userData) => dispatch => {
  return axios
    .post("api/users/updateaccount", userData)
    .then((res) => {
      dispatch(setCurrentUser(res.data));
      return res;
    }).catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    });
};

//Add sim number
export const addBilling = (userData) => dispatch => {
  return axios
    .post("api/billing/addBilling", userData)
    .then((res) => {
      return res;
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    });
}

export const changePassword = (userData, history, isAdmin) => dispatch => {
  axios
    .post("api/users/changePassword", userData)
    .then((res) => {
      if (isAdmin)
        history.push("/admin");
      else
        history.push("/dashboard");

      return res;
    })
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    );
}

export const passwordResetRequest = async (userData) => {
  let res;

  try {
    res = await axios.post(
      "api/reset-password-request", userData);
  } catch (error) {
    return error.response.status;
  }

  return res.status;
}

export const resetPassword = async (userData) => {
  let res;

  try {
    res = await axios.post(
      "api/reset-password", userData);
  } catch (error) {
    return error.response.status;
  }

  return res.status;
}

export const activateAccount = async (userData) => {
  let res;

  try {
    res = await axios.post(
      "api/activate-account", userData);
  } catch (error) {
    return error.response;
  }

  return res;
}

// Log user out
export const logoutUser = () => dispatch => {
  localStorage.removeItem("jwtToken");
  setAuthToken(false);
  dispatch(setCurrentUser({}));
};

export const getBillings = (code) => async dispatch => {
  const response = await axios.get('api/billing/getBillings', {
    params: { code: code }
  });

  dispatch({ type: 'FETCH_BILLINGS', payload: response.data })
}
