import React, { Component } from "react";
import { Link } from "react-router-dom";
import Footer from './Footer';

import img from '../../images/start_page_350_350.png';

class Landing extends Component {
  render () {
    return (
      <div style={{ height: "80vh", marginTop:"40px" }}
           className="container valign-wrapper">
        <div className="row">
          <div className="col s12 center-align">
            <h2>Welcome!</h2>
            <h5>Please sign in to continue</h5>
            <Link to="/login"
              className="btn-large waves-effect waves-light white-text mt">
              Login
              </Link>
              <div>
                  <img id="start-page-image"
                   src={img} alt="Are you Integrator?"
                   style={{marginTop:'20px'}} />
                   <h4>3 easy steps!</h4>
                   <p >Step 1: register online</p>
                   <p>Step 2: add the camera to your account</p>
                   <p>Step 3: receive your royalty on your banking account</p>
              </div>
              <Footer />
          </div>
        </div>
      </div>
    );
  }
}

export default Landing;
