import axios from "axios";

export const getStats = () => {
  return axios.get("api/admin/get-stats");
};

export const getAccounts = (options) => {
  let queryParams = ''

  if (options) {
    queryParams = '?'
    for (let option in options) {
      if (options[option])
        queryParams += `&${option}=${options[option]}`
    }
  }

  return axios.get(`api/admin/accounts${queryParams}`);
};

export const confirmAccount = async (id) => {
  return await axios.post('api/admin/accounts/approve', { id: id })
}

export const declineAccount = async (id) => {
  return await axios.post('api/admin/accounts/decline', { id: id })
}

export const uploadInventory = (file) => {
  return axios
    .post("api/admin/uploadInventory", file)
    .then((res) => {
      return res;
    })
    .catch(err => {
      console.log('err:', err)
    });
}

export const fetchRoyalties = () => async dispatch => {
  const response = await axios.get(`api/admin/fetchRoyalties`);

  dispatch({ type: 'FETCH_ROYALTIES', payload: response.data });
}

export const updatePayment = (data) => async dispatch => {
  let response = null;

  try {
    response = await axios.post("api/admin/updatePayment", data);
  } catch (err) {
    dispatch({
      type: 'UPDATE_TRANSACTIONS',
      payload: err.response.data.error
    });
  }
  if (response) {
    dispatch({ type: 'UPDATE_TRANSACTIONS', payload: response.data.message })
  }
}

export const getRewards = () => async dispatch => {
  const response = await axios.get("api/admin/getRewards")

  dispatch({ type: 'FETCH_AWARDS', payload: response.data.integratorsArr });
}
