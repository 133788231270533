import React, { Component } from "react"
import { withRouter } from "react-router-dom"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { registerUser } from "../../actions/authActions"
import { AccountInfo, CHOOSE_COUNTRY_OPTION, CHOOSE_PROVINCE_OPTION }
from '../accountManipulation/AccountInfo'
import classnames from "classnames"

const requiredFields = [
  'email',
  'first_name',
  'last_name',
  'company_name',
  'company_phone_number',
  'phone_number',
  'address',
  'city',
  'postal_code',
  'province',
  'country'
]

class Register extends Component {
  constructor () {
    super()
    this.state = {
      data: {
        email: "",
        first_name: '',
        last_name: '',
        company_name: '',
        company_phone_number: '',
        address: '',
        city: '',
        province: CHOOSE_PROVINCE_OPTION,
        country: CHOOSE_COUNTRY_OPTION,
        postal_code: '',
        phone_number: ''
      },
      provenance: '',
      loading: false,
      errors: {}
    }
  }

  componentDidMount () {
    if (this.props.auth.isAuthenticated) {
      this.props.history.push("/dashboard")
    }
  }

  componentWillReceiveProps (nextProps) {
    if (nextProps.auth.isAuthenticated) {
      this.props.history.push("/dashboard")
    }

    if (nextProps.errors) {
      this.setState({
        errors: nextProps.errors,
        loading: false
      })
    }
  }

  formCompleted () {
    for (let i = 0; i < requiredFields.length; i++) {
      if (this.state.data[requiredFields[i]] === '') {
        return false
      }
    }

    if (this.state.provenance === '')
      return false

    return true
  }

  handleProvenanceChange = e => {
    this.setState({
      provenance: e.target.value
    })
  }

  handleDataChange = e => {
    let tmpData = {...this.state.data}

    if (e.target.id === 'country') {
      tmpData.province = CHOOSE_PROVINCE_OPTION
    }

    tmpData[e.target.id] = e.target.value
    this.setState({
      data: tmpData
    })
  }

  onChange = e => {
    if (e.target.id === 'country') {
      this.setState({
        province: CHOOSE_PROVINCE_OPTION
      })
    }

    this.setState({ [e.target.id]: e.target.value })
  };

  onSubmit = e => {
    e.preventDefault()

    const newUser = {
      email: this.state.data.email,
      first_name: this.state.data.first_name,
      last_name: this.state.data.last_name,
      company_name: this.state.data.company_name,
      company_phone_number: this.state.data.company_phone_number,
      phone_number: this.state.data.phone_number,
      address: this.state.data.address,
      city: this.state.data.city,
      country: this.state.data.country,
      province: this.state.data.province,
      postal_code: this.state.data.postal_code,
      provenance: this.state.provenance
    }

    this.setState({
      loading: true
    })

    this.props.registerUser(newUser, this.props.history)
  }

  render () {
    const { errors } = this.state

    if (errors.hasOwnProperty('errors') && errors.errors.serverError) {
      return (
        <div className="container login-form">
          <div style={{ marginTop: "4rem" }} className="row">
            <div className="col s12">
              <h4>Sorry, an error occured while processing your request.</h4>
              <p>
                Please contact our support team for assistance:
                <br /><br />
                <strong>Phone:</strong>
                <br /><br />
                United States: 1-888-986-7537
                <br />
                Canada and other countries: 1-866-986-7537
                <br /><br />
                <strong>Email:</strong>
                <br /><br />
                support@vosker.com
              </p>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className="container register-form">
        <form noValidate onSubmit={this.onSubmit}>
          <fieldset
            disabled={ this.state.loading }
            style={{ border: 'none '}}>
            <AccountInfo data={ this.state.data }
                         errors={ this.state.errors }
                         onDataChange={ this.handleDataChange }/>

            <div className="row">
              <div className="col s12 input-field">
                <input
                  onChange={this.handleProvenanceChange}
                  value={this.state.provenance}
                  id="provenance"
                  type="text"
                  className={classnames("", {
                    invalid: this.state.errors.provenance
                  })}
                />
                <label htmlFor="provenance">
                  Where did you get your camera from:
                </label>
                <span className="red-text">
                  { this.state.errors.provenance }
                </span>
              </div>

              <div className="row">
                <div className="col s12 mb-2">
                  <button
                    disabled={ !this.formCompleted() }
                    type="submit"
                    className="btn-large waves-effect waves-light white-text">
                    Sign up
                  </button>
                </div>
              </div>
            </div>
          </fieldset>
        </form>

        { this.state.loading &&
          (
            <div className="row mb">
              <div className="col s12">
                <div className="progress">
                  <div className="indeterminate"></div>
                </div>
              </div>
            </div>
          )
        }
      </div>
    )
  }
}

Register.propTypes = {
  registerUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
})

export default connect(
  mapStateToProps,
  { registerUser }
)(withRouter(Register))
