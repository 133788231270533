import React from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import BankInfo from "./BankInfo";
import { AccountInfo, CHOOSE_COUNTRY_OPTION, CHOOSE_PROVINCE_OPTION }
from '../accountManipulation/AccountInfo';
import Materialize from 'materialize-css';
import axios from "axios";
import { updateAccount } from "../../actions/authActions";
import { flattenUserData } from '../../utils/converters'

const requiredFields = [
  'first_name',
  'last_name',
  'company_name',
  'company_phone_number',
  'phone_number',
  'address',
  'city',
  'postal_code',
  'province',
  'country'
];

const bankInfoRequiredFields = [
  'bank_name',
  'bank_address',
  'bank_code',
  'account_number',
  'branch_number',
  'holder_name'
];

class UpdateAccount extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      data: {
        email: "",
        first_name: '',
        last_name: '',
        company_name: '',
        company_phone_number: '',
        address: '',
        city: '',
        province: CHOOSE_PROVINCE_OPTION,
        country: CHOOSE_COUNTRY_OPTION,
        postal_code: '',
        phone_number: '',
        provenance: '',
        division: ''
      },
      checks: "",
      bank_name: '',
      bank_address: '',
      bank_code: '',
      account_number: '',
      branch_number: '',
      holder_name: '',
      checkboxChecked: false,
      divisionRequired: false,
      errors: {},
    };
  }

  componentDidMount () {
    const { user } = this.props.auth;
    let newState = {};

    if (!this.props.auth.isAuthenticated) {
      this.props.history.push("/login");
      return;
    }

    axios
      .get('api/users/getIntegrator', {
        params: { id: user.id }
      })
      .then(response => {
        if (!response.data) return

        const userData = response.data.bankInfo;
        newState.data = flattenUserData(response.data)
        newState.checkboxChecked = userData.checks === 'yes' ? true : false;
        newState.checks = userData.checks;
        newState.bank_name = userData.bank_name;
        newState.bank_code = userData.bank_code;
        newState.bank_address = userData.bank_address;
        newState.account_number = userData.account_number;
        newState.branch_number = userData.branch_number;
        newState.holder_name = userData.holder_name;

        this.setState(newState);
      });
  }

  componentDidUpdate () {
    if (!this.props.auth.isAuthenticated) {
      this.props.history.push("/login");
    }
    Materialize.updateTextFields();
  }

  handleDataChange = e => {
    let tmpData = { ...this.state.data };
    tmpData[e.target.id] = e.target.value;

    let newState = {
      data: tmpData
    };

    if (e.target.id === 'country') {
      newState.data.province = CHOOSE_PROVINCE_OPTION;
    }

    this.setState(newState);
  }

  formCompleted () {
    if (this.state.checks === 'no') {
      for (let i = 0; i < bankInfoRequiredFields.length; i++) {
        if (this.state[bankInfoRequiredFields[i]] === '') {
          return false;
        }
      }
    }

    for (let i = 0; i < requiredFields.length; i++) {
      if (this.state.data[requiredFields[i]] === '') {
        return false;
      }
    }

    return true;
  }

  handleBankInfoChange = e => {
    this.setState({
      [e.target.id]: e.target.value
    });
  }

  onCheckBoxChange = () => {
    let newState = {};
    newState.checkboxChecked = !this.state.checkboxChecked;
    newState.checks = newState.checkboxChecked ? 'yes': 'no';
    this.setState(newState);
  }

  onSubmit = e => {
    e.preventDefault();
    const { user } = this.props.auth;

    let newData = { ...this.state.data };
    newData.checks = this.state.checks;
    newData.bank_name = this.state.bank_name;
    newData.bank_address = this.state.bank_address;
    newData.bank_code = this.state.bank_code;
    newData.account_number = this.state.account_number;
    newData.branch_number = this.state.branch_number;
    newData.holder_name = this.state.holder_name;
    newData.id = user.id

    this.props.updateAccount(newData, this.props.history)
      .then(res => {
        if (res && res.status === 200) {
          this.props.history.push("/dashboard?show-account-updated=true");
        } else {
          this.setState({
            errors: this.props.errors
          })
        }
      });
  };

  render () {
    const { errors } = this.state;
    return (
      <div className="container login-form">
        <div className="row">
          <div className="col s12">
            <form noValidate onSubmit={this.onSubmit}>
              <AccountInfo data={ this.state.data }
                           errors={ errors }
                           emailReadOnly
                           onDataChange={ this.handleDataChange } />
            </form>
          </div>

          <div className="row mt">
            <div className="col s12">
              <p>Royalties will be paid by bank transfer. Please provide the information for the bank account that will receive the transfers.</p>
              <p>If you wish to be paid by check, please check the option bellow.</p>
              <p><b>Please note: if you want to skip this step, just click save and you can always update this information later.</b></p>
            </div>
          </div>

          <div className="row mt">
            <div className="col s12 mb">
              <label>
                <input type="checkbox" checked={this.state.checkboxChecked} onChange={this.onCheckBoxChange} />
                <span><b>I want to receive checks</b></span>
              </label>
            </div>
          </div>

          { !this.state.checkboxChecked &&
            <div id="bankInfoWrapper" className="mt">
              <BankInfo
                onBankInfoChange={this.handleBankInfoChange}
                bank_name={this.state.bank_name}
                bank_address={this.state.bank_address}
                bank_code={this.state.bank_code}
                account_number={this.state.account_number}
                branch_number={this.state.branch_number}
                holder_name={this.state.holder_name}
              />
            </div>
          }

          <div className="col s12 mb-2">
            <button
              style={{
                width: "150px",
                borderRadius: "3px",
                letterSpacing: "1.5px"
              }}
              disabled={ !this.formCompleted() }
              onClick={this.onSubmit}
              type="submit"
              className="btn-large waves-effect waves-light white-text">
              Save
            </button>
          </div>
        </div>
      </div>
    );
  }
}

UpdateAccount.propTypes = {
  updateAccount: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    errors: state.errors
  };
};

export default connect(
  mapStateToProps,
  { updateAccount }
)(withRouter(UpdateAccount));
