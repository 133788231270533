function calculateTransactionsSum(transactions) {
    let amount = 0;
    let current_date = new Date();
    transactions.forEach((transaction) => {
      let date = new Date(transaction);
      if ((date.getFullYear === current_date.getFullYear) && (transaction.closed.length === 0)) {
        amount += transaction.sum;
      }
    })
    return amount;
  }

  function calculateRoyalties(sims) {
    let count = 0;
    let royalties = 0;
    if(sims.length>99){
      count = sims.length - 99;
    }
    sims.forEach((sim) => {
      if (count > 0) {
        royalties += Math.floor(calculateTransactionsSum(sim.transactions) * 0.15 * 100) / 100;
        count--;
      } else {
        royalties += Math.floor(calculateTransactionsSum(sim.transactions) * 0.1 * 100) / 100;
      }
    });
    royalties = Math.floor(royalties * 100) / 100;
  
    return royalties;
  }

  module.exports = {
    calculateRoyalties,
    calculateTransactionsSum
  }