import React, { Component } from "react";
import { HashRouter as Router, Route, Switch } from "react-router-dom";

import { Provider } from "react-redux";
import { persistor, store } from "./store";

import { PersistGate } from 'redux-persist/lib/integration/react'

import LoadingView from './components/layout/LoadingView'
import Navbar from "./components/layout/Navbar";
import Landing from "./components/layout/Landing";
import Register from "./components/auth/Register";
import Login from "./components/auth/Login.js";
import { PrivateRoute, AdminPrivateRoute } from
"./components/private-route/PrivateRoute";
import Dashboard from "./components/dashboard/Dashboard";
import AddSim from "./components/accountManipulation/AddSim";
import ConfirmRegister from "./components/dashboard/ConfirmRegister";
import UpdateAccount from "./components/accountManipulation/UpdateAccount";
import ChangePassword from "./components/accountManipulation/ChangePassword";
import ResetPasswordRequest from "./components/auth/ResetPasswordRequest";
import ActivateAccount from "./components/auth/ActivateAccount";
import ResetPassword from "./components/accountManipulation/ResetPassword";
import AdminDashboard from "./components/admin/AdminDashboard";
import UserList from "./components/admin/UserList";
import UserDetail from "./components/admin/UserDetail";
import "./App.css";

class App extends Component {
  render () {
    return (
      <Provider store={store}>
        <PersistGate persistor={ persistor } loading={ <LoadingView />}>
          <Router>
            <div className="App" style={{padding:'0, 10px, 0, 10px'}}>
              <Navbar />
              <div className="main content" style={{marginTop:'25px'}}>
                <Route exact path="/" component={Landing} />
                <Route exact path="/register" component={Register} />
                <Route exact path="/login" component={Login} />
                <PrivateRoute exact path="/updateaccount" component={UpdateAccount} />
                <PrivateRoute exact path="/addSim" component={AddSim} />
                <Route exact path="/activate-account"
                       component={ActivateAccount} />
                <Route exact path="/confirmreg" component={ConfirmRegister} />
                <PrivateRoute exact path="/changePassword" component={ChangePassword} />
                <Route exact path="/resetPasswordRequest"
                       component={ResetPasswordRequest} />
                <Route exact path="/resetPassword"
                       component={ResetPassword} />
                <Switch>
                  <PrivateRoute exact
                                path="/dashboard"
                                component={Dashboard} />
                  <AdminPrivateRoute exact path="/admin"
                                component={AdminDashboard} />
                  <AdminPrivateRoute exact path="/admin/users"
                                component={UserList} />
                  <AdminPrivateRoute exact path="/admin/users/detail"
                                component={UserDetail} />
                </Switch>
              </div>
            </div>
          </Router>
        </PersistGate>
      </Provider>
    );
  }
}
export default App;
