import React, { Component } from 'react';

class LoadingView extends Component {
    render () {
        return (
            <div>
              <h3>Loading...</h3>
            </div>
        );
    }
}

export default LoadingView;
