import React from 'react';
import { resetPassword } from '../../actions/authActions';
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import * as httpStatus from 'http-status-codes';
import * as qs from 'query-string';
import * as base64 from 'base-64';

class ResetPassword extends React.Component {
  state = {
    password: '',
    repeatPassword: '',
    errorNotEqual: false,
    errorToken: false,
    loading: false,
    isEmpty: true
  };

  componentDidMount () {
    const params = qs.parse(this.props.location.search);
    if (!params.token) {
      this.setState({
        errorToken: true
      });
    } else {
      const decodedToken = base64.decode(params.token);
      this.setState({
        token: decodedToken
      });
    }
  }

  onChange = (e) => {
    let isEmpty = false;
    if (this.state.password === '' || this.state.repeatPassword === '') {
      isEmpty = true;
    }
    this.setState({
      [e.target.id]: e.target.value,
      isEmpty: isEmpty
    });
  }

  onSubmit = async (e) => {
    e.preventDefault();

    if (this.state.password !== this.state.repeatPassword) {
      this.setState({
        errorNotEqual: true
      });
    } else {
      this.setState({
        loading: true
      });

      const status = await resetPassword({
        password: this.state.password,
        token: this.state.token
      });

      let newState = {};
      switch (status) {
        case httpStatus.OK:
          newState = {
            success: true
          }
          break;
        default:
          newState = {
            error: true
          }
      }

      newState.loading = false;
      newState.errorNotEqual = false;
      this.setState(newState);
    }
  }

  render () {
    return (
      <div className="container">
        <div style={{ marginTop: "4rem" }} className="row">
          <div className="col s8 offset-s2">
            <form noValidate onSubmit={this.onSubmit}>
              <div className="col s12">
                <h3>
                  <strong>Reset your password</strong>
                </h3>
              </div>

              { this.state.success &&
                (
                  <div className="col s12">
                    <p style={{ marginTop: '10px' }}>
                      Your password has been successfully changed.
                      Please click on this <Link to="/login">link</Link> to go to the login page.
                    </p>
                  </div>
                )
              }

              { this.state.error &&
                (
                  <div className="col s12">
                    <p className='red-text' style={{ marginTop: '10px' }}>
                      An error occured while trying to process your request.
                    </p>
                  </div>
                )
              }

              { this.state.loading &&
                (
                  <div className="col s12">
                    <div className="progress">
                      <div className="indeterminate"></div>
                    </div>
                  </div>
                )
              }

              { !this.state.error && !this.state.success &&
                (
                  <div>
                    <div className="input-field col s12">
                      <input
                        disabled={ this.state.loading }
                        onChange={this.onChange}
                        value={this.state.new_password}
                        id="password"
                        type="password"
                      />
                      <label htmlFor="password">New Password</label>
                    </div>
                    <div className="input-field col s12">
                      <input
                        disabled={ this.state.loading }
                        onChange={this.onChange}
                        value={this.state.new_password2}
                        id="repeatPassword"
                        type="password"
                      />
                      <label htmlFor="repeatPassword">
                        Confirm New Password
                      </label>
                      { this.state.errorNotEqual &&
                        (
                          <span className="red-text">
                            Passwords do not match.
                          </span>
                        )
                      }
                    </div>
                    <div className="right floated content">
                      <button
                        disabled={ this.state.loading || this.state.isEmpty }
                        className="btn-large waves-effect waves-light white-text"
                        style={{
                          width: "150px",
                          borderRadius: "3px",
                          letterSpacing: "1.5px",
                          marginTop: "1rem"
                        }}
                        type="submit">
                        Submit
                      </button>
                    </div>
                  </div>
                )
              }
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default connect()(withRouter(ResetPassword));
