import React from "react";
import { connect } from "react-redux";
import * as httpStatus from 'http-status-codes';
import { passwordResetRequest } from "../../actions/authActions";

class PasswordResetRequest extends React.Component {
  constructor () {
    super();
    this.state = {
      email: "",
      success: false,
      notFound: false,
      error: false,
      loading: false
    };
  }

  renderError () {
    if (this.state.notFound) {
      return 'This email was not found in our system.';
    }
  }

  onEmailChange = e => {
    this.setState({
      email: e.target.value
    });
  }

  onSubmit = async (e) => {
    e.preventDefault();

    this.setState({
      error: false,
      success: false,
      notFound: false,
      loading: true
    });

    const status = await passwordResetRequest({
      email: this.state.email
    });

    let newState = {};
    switch (status) {
      case httpStatus.OK:
        newState = {
          success: true
        }
        break;
      case httpStatus.NOT_FOUND:
        newState = {
          notFound: true
        }
        break;
      default:
        newState = {
          error: true
        }
    }

    newState.loading = false;
    this.setState(newState);
  };

  render () {
    return (
      <div className="container">
        <div style={{ marginTop: "4rem" }} className="row">
          <div className="col s8 offset-s2">

            <div className="col s12"
                 style={{ paddingLeft: "11.250px", marginBottom: "10px" }}>
              <h3>
                <strong>Request a new password</strong>
              </h3>
            </div>

            { this.state.error &&
              (
                <div className="col s12">
                  <p>An error occured while processing your request. Please try again or contact us for assistance.</p>
                </div>
              )
            }

            { this.state.success &&
              (
                <div className="col s12">
                  <p>An email has been sent to { this.state.email }.
                    Your request will be valid for 30 minutes.</p>
                </div>
              )
            }

            { this.state.loading &&
              (
                <div className="col s12">
                  <div className="progress">
                    <div className="indeterminate"></div>
                  </div>
                </div>
              )
            }

            { !this.state.success && !this.state.error &&
              (
                <form noValidate onSubmit={this.onSubmit}>
                  <div className="input-field col s12">
                    <input
                      disabled={ this.state.loading }
                      onChange={ this.onEmailChange }
                      value={ this.state.email }
                      type="email"
                    />
                    <label htmlFor="email">Email</label>
                    <span className="red-text">
                      { this.renderError() }
                    </span>
                  </div>
                  <div className="col s12" style={{ paddingLeft: "11.250px" }}>
                    <button
                      style={{
                        width: "150px",
                        borderRadius: "3px",
                        letterSpacing: "1.5px",
                        marginTop: "1rem"
                      }}
                      disabled={ !this.state.email || this.state.loading }
                      type="submit"
                      className="btn-large waves-effect waves-light white-text"
                    >
                      Submit
                    </button>
                  </div>
                </form>
              )
            }
          </div>
        </div>
      </div>
    );
  }
}

export default connect()(PasswordResetRequest);
