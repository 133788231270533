import React from 'react';
import { Link } from "react-router-dom";
import { getStats } from '../../actions/adminActions';

class AdminDashboard extends React.Component {
  constructor (props) {
    super(props);

    this.state = {
      users: 'N/A',
      cameras: 'N/A',
      cards: 'N/A'
    }
  }

  componentDidMount () {
    getStats()
      .then(response => {
        this.setState(response.data);
      })
      .catch(() => {
        console.log('unable to load stats.')
      });
  }

  render () {
    return (
      <div className="container">
        <div className="row" style={{ marginLeft: '40px'}}>
          <div className="col s3 offset-s1">
            <Link to="/admin/users">
              <div className="card light-blue darken-1">
                <div className="card-content white-text center">
                  <span className="card-title">Integrators</span>
                  <i className="material-icons large">account_circle</i>
                  <h4>{ this.state.users }</h4>
                </div>
              </div>
            </Link>
          </div>

          <div className="col s3">
              <div className="card light-blue lighten-1">
                <div className="card-content white-text center">
                  <span className="card-title">Activated cameras</span>
                  <i className="material-icons large">camera_alt</i>
                  <h4>{ this.state.cameras }</h4>
                </div>
              </div>
          </div>

          <div className="col s3">
              <div className="card light-blue lighten-1">
                <div className="card-content white-text center">
                  <span className="card-title">Cards inventory</span>
                  <i className="material-icons large">sd_card</i>
                  <h4>{ this.state.cards }</h4>
                </div>
              </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AdminDashboard;
